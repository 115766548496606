import { useState } from "react";
import styled from "styled-components";
import GroupIcon from "@mui/icons-material/Group";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import demoImg from "../../assets/demoImg.svg";
import BackdropWrapper from "./BackdropWrapper";
import RecipeForm from "./RecipeForm";
import QuickAddRecipe from "./QuickAddRecipe";
import FavoriteRecipeHandler from "../recipes/FavoriteRecipeHandler";
import useGenerateImg from "../../hooks/useGenerateImg";
import { useEffect } from "react";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../../authentication/firebase";
import { CircularProgress, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShareRecipe from "./ShareRecipe";
import LoadingButton from "../LoadingButton";
import { alertActions } from "../../store/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import InstacartLink from "../InstacartLink";
import moment from "moment";
import { modalActions } from "../../store/modalSlice";

const ingredientMeasures = "us";
function RecipeDetails({ handleBackdrop, data, selectedDate, hideCloseIcon }) {
  const imageGeneration = useGenerateImg();
  // if (
  //   data.recipeType === "favorite" ||
  //   data.recipeType === "web" ||
  //   data.recipeType === "manual"
  // )
  // data = Object.assign({}, data);
  const [editBackdrop, setEditBackdrop] = useState(false);
  const [addBackdrop, setAddBackdrop] = useState(false);
  const [shareBackdrop, setShareBackdrop] = useState(false);
  const [recipeImg, setRecipeImg] = useState(data.image);
  const [deleteRecipeLoading, setDeleteRecipeLoading] = useState(false);
  const [addToCartLoading, setAddToCartLoading] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isAdmin = user.info.isAdmin;

  const editRecipeHandler = (e, type) => {
    setEditBackdrop((prev) => !prev);
  };
  const addRecipeHandler = () => {
    setAddBackdrop((prev) => !prev);
  };
  const shareRecipeHandler = () => {
    setShareBackdrop((prev) => !prev);
  };

  if (!data.savedIngredients || data.savedIngredients?.length === 0) {
    data.savedIngredients = data.extendedIngredients
      ? [...data.extendedIngredients]
      : [...data.ingredients];
  }

  const [servings, setServings] = useState(data.savedServings);
  const [ingredients, setIngredients] = useState(data.savedIngredients || 1);

  console.log("data", data.savedServings);
  if (!data.savedServings) {
    // data.savedServings = data.servings;
    servingsChangeHandler(1);
  }

  let steps = [];
  data.steps !== undefined
    ? (steps = data.steps)
    : data.analyzedInstructions?.forEach((instruction) =>
        instruction.steps?.forEach((item) => steps.push(item.step))
      );

  function servingsChangeHandler(newValue) {
    if (newValue !== 0) {
      setServings(newValue);
      data.savedServings = newValue;

      setIngredients((prev) => {
        let newIngredientValues = prev.map((ingredient, index) => {
          let ingredientAmount = data.extendedIngredients
            ? data.extendedIngredients[index].measures[ingredientMeasures]
                .amount
            : data.ingredients[index].amount;

          ingredientAmount = parseFloat(
            ((ingredientAmount / data.servings) * newValue
          ).toFixed(2));
          console.log("ingredient amount", ingredientAmount)
          return {
            ...ingredient,
            amount: ingredientAmount,
            measures: {
              [ingredientMeasures]: { ...ingredient.measures[ingredientMeasures],amount: ingredientAmount },
            },
          };
        });
        console.log("before", data.extendedIngredients, data.servings);
        data.savedIngredients = newIngredientValues;
        console.log("after", newIngredientValues);
        return newIngredientValues;
      });
    }
  }

  async function deleteRecipeHandler() {
    setDeleteRecipeLoading(true);
    try {
      console.log("delete recipe", isAdmin, data.recipeType, data.id);
      await deleteDoc(doc(db, `${data.recipeType}Recipes`, data.id));
      dispatch(
        alertActions.setAlert({
          title: "Recipe deleted successfully!",
          messageType: "success",
        })
      );
      handleBackdrop();
    } catch (e) {
      dispatch(
        alertActions.setAlert({
          title: "Recipe deletion unsuccessful",
          messageType: "error",
        })
      );
      console.log("error while deleting recipe", e);
    } finally {
      setDeleteRecipeLoading(false);
    }
  }

  async function addToCartHandler() {
    if (!user.uid) {
      localStorage.setItem("stackedRecipe", JSON.stringify(data));
      localStorage.setItem("routePath", `/recipe/${data.id}`);
      dispatch(modalActions.setLoginModalActive(true));
      return;
    }
    setAddToCartLoading(true);
    try {
      const date = moment(new Date()).format("YYYY-MM-DD");
      const yy = date.split("-")[0];
      const mm = date.split("-")[1];
      const dd = date.split("-")[2];
      const cartRef = doc(db, `shoppingCart/${user.uid}/${yy}-${mm}`, dd);
      await setDoc(
        cartRef,
        {
          [data.dishTypes[0]]: ingredients,
        },
        { merge: true }
      );
      dispatch(
        alertActions.setAlert({
          title: "Ingredients added to cart",
          messageType: "success",
        })
      );
    } catch (e) {
      dispatch(
        alertActions.setAlert({
          title: "Failed to add to cart",
          messageType: "error",
        })
      );
      console.log("error while adding ingredients to cart", e);
    } finally {
      setAddToCartLoading(false);
    }
  }

  async function renderImg() {
    if (
      !data.image &&
      (data.recipeType === "gpt" || data.recipeType === "converted")
    ) {
      try {
        console.log("prompts", data.title, data.ingredients, data.id);
        const url = await imageGeneration(
          data.title,
          data.ingredients,
          data.id
        );
        data.imageGenerating = false;
        data.image = url;
        setRecipeImg(url);

        const docRef = doc(db, `${data.recipeType}Recipes`, data.id);
        const res = await setDoc(docRef, data);
        console.log("res", res);
      } catch (e) {
        setRecipeImg(demoImg);
        console.log("recipe generate error, details page", e);
      }
    }
  }

  useEffect(() => {
    renderImg();
    // fetchInstacartRecipeLink();
    // console.log("recipe details", data);
  }, []);

  return (
    <StyledDetails>
      <BackdropWrapper
        open={addBackdrop}
        handleBackdrop={addRecipeHandler}
        element={
          <QuickAddRecipe
            data={data}
            selectedServings={servings}
            handleBackdrop={addRecipeHandler}
            selectedDate={selectedDate}
          />
        }
      />
      <BackdropWrapper
        open={editBackdrop}
        handleBackdrop={editRecipeHandler}
        element={
          <RecipeForm
            data={data}
            recipeType={data.recipeType}
            handleBackdrop={editRecipeHandler}
          />
        }
      />
      <BackdropWrapper
        open={shareBackdrop}
        handleBackdrop={shareRecipeHandler}
        element={
          <ShareRecipe recipeData={data} handleBackdrop={shareRecipeHandler} />
        }
      />
      <div className={`heading ${!hideCloseIcon && "modal-active"}`}>
        <h4>{data?.title}</h4>
        {!hideCloseIcon && (
          <button className="icon" onClick={(e) => handleBackdrop(false)}>
            {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
          </button>
        )}
      </div>
      <div className="content">
        <div className="main">
          <div className="img-div">
            {!recipeImg || recipeImg === "" ? (
              <CircularProgress
                style={{
                  color: "var(--primary-color)",
                  width: 20,
                  height: 20,
                }}
              />
            ) : (
              <div className="img">
                <img src={recipeImg} alt="plate" />
                <FavoriteRecipeHandler data={data} />
              </div>
            )}
            <div className="btns">
              <InstacartLink data={data} title="Shop Ingredients" />
              <button
                className="accent-btn"
                onClick={(e) => setShareBackdrop(true)}
              >
                Share
              </button>
              <LoadingButton
                loading={!data.nutrition}
                onClick={addRecipeHandler}
                title={"Add to Calendar"}
              />
              {data?.extendedIngredients?.length > 0 && (
                <LoadingButton
                  loading={addToCartLoading}
                  onClick={addToCartHandler}
                  title={"Add to Cart"}
                  className="secondary-btn"
                />
              )}
              {isAdmin && (
                <LoadingButton
                  loading={deleteRecipeLoading}
                  onClick={deleteRecipeHandler}
                  className="secondary-btn"
                  title={"Delete Recipe"}
                />
              )}
              {/* {(data.recipeType === "web" || data.recipeType === "manual") && (
                <button onClick={editRecipeHandler}>Edit</button>
              )} */}
            </div>
          </div>
          <div className="info">
            <div className="details">
              <div className="size">
                <GroupIcon fontSize="medium" htmlColor="#F79C16" />
                {/* <h5>Servings:</h5> */}
                <Tooltip placement="top" title="Change servings">
                  <input
                    type="number"
                    inputMode="decimal"
                    min={1}
                    max={25}
                    step={1}
                    value={servings}
                    onChange={(e) =>
                      servingsChangeHandler(parseInt(e.target.value))
                    }
                  />
                </Tooltip>
              </div>
              <div className="time">
                <AvTimerIcon fontSize="medium" htmlColor="#F79C16" />
                <p>{data.readyInMinutes}min</p>
              </div>
              <div className="meal-type">
                <RestaurantIcon fontSize="medium" htmlColor="#F79C16" />
                <p>
                  {data?.dishType !== undefined
                    ? data.dishType
                    : data.dishTypes?.[0]}
                </p>
              </div>
              {data.healthScore && (
                <div className="health-score">
                  <MonitorHeartIcon fontSize="medium" htmlColor="#F79C16" />
                  <p>{parseInt(data.healthScore) / 10}/10</p>
                </div>
              )}
            </div>
            {data.nutrition?.nutrients && (
              <>
                <h5 className="header">Nutrition per Serving</h5>
                <div className="details">
                  <div>
                    <h5>Calories:</h5>
                    <p>
                      {data.nutrition.nutrients.map(
                        (nutrient) =>
                          nutrient.name === "Calories" &&
                          parseInt(nutrient.amount)
                      )}
                    </p>
                  </div>
                  <div>
                    <h5>Protein:</h5>
                    <p>
                      {data.nutrition.nutrients.map(
                        (nutrient) =>
                          nutrient.name === "Protein" &&
                          parseInt(nutrient.amount)
                      )}
                      g
                    </p>
                  </div>
                  <div>
                    <h5>Carbs:</h5>
                    <p>
                      {data.nutrition.nutrients.map(
                        (nutrient) =>
                          nutrient.name === "Carbohydrates" &&
                          parseInt(nutrient.amount)
                      )}
                      g
                    </p>
                  </div>
                  <div>
                    <h5>Fat:</h5>
                    <p>
                      {data.nutrition.nutrients.map(
                        (nutrient) =>
                          nutrient.name === "Fat" && parseInt(nutrient.amount)
                      )}
                      g
                    </p>
                  </div>
                </div>
              </>
            )}
            <p>{data.summary}</p>
          </div>
        </div>
        <div className="mid main">
          <div className="ingredients">
            <h5>Ingredients</h5>
            <div>
              <ul>
                {ingredients?.map((ingredient, index) => (
                  <li key={index}>
                    {ingredient.nameClean?.[0].toUpperCase() +
                      ingredient.nameClean?.slice(1)}
                    ,{" "}
                    {ingredient.measures
                      ? `${ingredient.measures[ingredientMeasures].amount} ${ingredient.measures[ingredientMeasures].unitShort}`
                      : `${ingredient.amount} ${ingredient.unit}`}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="extra-info">
            {/* {data.carbonFootprint && (
              <div className="info-div">
                <h5>Carbon Footprint</h5>
                <p>{data.carbonFootprint}</p>
              </div>
            )}
            {data.foodWaste && (
              <div className="info-div">
                <h5>Food Waste</h5>
                {Object.entries(data.foodWaste).map(([key, value]) => (
                  <h5>
                    {key}: <span>{value}</span>
                  </h5>
                ))}
              </div>
            )} */}
            {data.healthBenefitsShort && (
              <div className="info-div">
                <h5>Health Benefits</h5>
                <div>
                  <ul>
                    {data.healthBenefitsShort.length > 0 ? (
                      data.healthBenefitsShort.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))
                    ) : (
                      <li>Not available</li>
                    )}
                  </ul>
                </div>
              </div>
            )}
            {data.dietCategory && (
              <div className="info-div">
                <h5>Diet Category</h5>
                <div>
                  <ul>
                    {data.dietCategory.length > 0 ? (
                      data.dietCategory.map((category, index) => (
                        <li key={index}>
                          {category[0].toUpperCase() + category.slice(1)}
                        </li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
              </div>
            )}
            {data.alternativeRecipes && (
              <div className="info-div">
                <h5>Related Recipes</h5>
                <div>
                  <ul>
                    {data.alternativeRecipes.length > 0 ? (
                      data.alternativeRecipes.map((recipeName, index) => (
                        <li key={index}>{recipeName}</li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
              </div>
            )}
            {/* {data.healthyAlternativeRecipes && (
              <div className="info-div">
                <h5>Healthy Alternative Recipes</h5>
                <div>
                  <ul>
                    {data.healthyAlternativeRecipes.length > 0 ? (
                      data.healthyAlternativeRecipes.map(
                        (recipeName, index) => <li key={index}>{recipeName}</li>
                      )
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className="steps">
          <h5>Steps</h5>
          {steps?.map((step, index) => (
            <div key={index}>
              <span>{index + 1}.</span>
              <p>{step}</p>
            </div>
          ))}
        </div>
      </div>
    </StyledDetails>
  );
}

const StyledDetails = styled.div`
  /* display: flex;
  flex-direction: column;
  gap: 20px; */
  background-color: var(--background-color);
  li {
    list-style: circle;
    line-height: 1.4;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    min-height: 46px;
    padding: 10px 0;
  }
  .modal-active {
    z-index: 1000;
    position: sticky;
    left: 0;
    top: 0%;
    border-bottom: 1px solid var(--shadow);
    background-color: var(--background-color);
  }
  .content {
    width: 98%;
    margin: auto;
    .main {
      display: flex;
      gap: 20px;
      margin: 10px auto;
      .img-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        min-width: 150px;
        min-height: 100px;

        .img {
          position: relative;
          img {
            width: 100%;
            border-radius: var(--radius);
          }
        }
        .btns {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 10px;
        }
        .favorite-btn {
          position: absolute;
          top: 5%;
          right: 5%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        p {
          max-width: 45ch;
        }
        .details {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 6px;
          input {
            width: 70px;
            padding: 0px 8px;
            border-color: var(--primary-color);
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            opacity: 1;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
          }
          h5 {
            color: var(--secondary-dark-color);
          }
          p {
            font-weight: bold;
          }
        }
      }
    }
    .mid {
      .ingredients,
      .extra-info {
        flex: 1 1 50%;
      }
      .ingredients {
        p {
          display: inline-block;
        }
      }
      .info-div {
        margin-bottom: 10px;
      }
    }
    .steps {
      div {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 4px 0;
        span {
          /* display: inline-block; */
          font-weight: 600;
        }
      }
    }
  }
  @media (max-width: 700px) {
    .main {
      flex-wrap: wrap;
      justify-content: center;
      .info {
        width: 100%;
      }
    }
    .content .main .img-div {
      width: 100%;
    }
  }
  @media (min-width: 700px) {
    .img-div {
      width: 400px;
    }
  }
`;

export default RecipeDetails;
