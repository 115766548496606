import React from "react";
import styled from "styled-components";
import LoadingButton from "../LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
import firebaseApi from "../../utils/firebaseApi";
import { useState } from "react";

export default function NewletterModal({ backdropHandler, title, sendgridListId }) {
  const emailRef = useRef();
  const [loading, setLoading] = useState(false)

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const res = await firebaseApi.get(
        `/addEmailToSendgrid?email=${emailRef.current.value}&sendgridListId=${sendgridListId}`
      );
      console.log("newsletter response", res.data);
      backdropHandler()
    } catch (e) {
      console.log("error while adding email to sendgrid", e);
    } finally{
      setLoading(false)
    }
  };

  return (
    <>
      <StyledPass>
        <div className="heading">
          <h4>{title}</h4>
          <button className="icon" onClick={(e) => backdropHandler(false)}>
            {<CloseIcon htmlColor="#E94C4F" fontSize="large" />}
          </button>
        </div>

        <form onSubmit={submitHandler} className="signup">
          {/* <input
            type="text"
            placeholder="Type name here"
           
          /> */}
          <input type="email" placeholder="Type email here" ref={emailRef} />

          <LoadingButton
            type="submit"
            title="Submit"
            className="secondary-btn"
            loading={loading}
          />
        </form>
      </StyledPass>
    </>
  );
}

const StyledPass = styled.div`
  p {
    text-align: center;
    max-width: 35ch;
    margin: auto;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .icon:hover {
    background-color: transparent;
  }

  .signup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    input {
      width: 70%;
      margin: auto;
    }

    .secondary-btn {
      background-color: #2dc65b;
      border-radius: 24px;
      &:hover {
        background-color: #50cc6d;
      }
    }
  }
`;
