import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeroImg from "./assets/images/HeroImage.jfif";
import HeroImg2 from "./assets/images/female cooking.jpg";
import HeroImg3 from "./assets/images/influencer.jpg";
import Navbar from "./Navbar";
import { Element } from "react-scroll";
import BackdropWrapper from "../../components/modals/BackdropWrapper";
import NewletterModal from "../../components/modals/NewletterModal";

export default function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [HeroImg, HeroImg2, HeroImg3];

  const [backdrop, setBackdrop] = useState(false);

  const backdropHandler = () => {
    setBackdrop((prev) => !prev);
  };


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(timer);
  }, [images.length]);

  return (
    <Element name="home">
      <StyledHome>
        <Navbar className="navbar" />
        <BackdropWrapper
          handleBackdrop={backdropHandler}
          smallSize={true}
          element={<NewletterModal title={"Subscribe to Newsletter"} backdropHandler={backdropHandler} sendgridListId={"44a7af7c-4a02-4e14-bf62-2115bc51ba5b"} />}
          open={backdrop}
        />
        <div className="main_container">
          <div className="hero_content">
            <h2>
              Share your recipes with the world, Grow your Followers, and Turn
              you passion into reality.
            </h2>
            <p>Grow your influence with Break Bread.</p>
            <button onClick={backdropHandler} className="secondary-btn">
              Join our Insider List today!
            </button>
            <p>
              Get beta access, first equity offerings, exclusive features
              access.
            </p>
          </div>
          <div className="right_side">
            <div className="image-container">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Hero ${index}`}
                  className={
                    currentImageIndex === index
                      ? "image-visible"
                      : "image-hidden"
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </StyledHome>
    </Element>
  );
}

const StyledHome = styled.div`
  height: 750px;
  overflow: hidden;
  position: relative;

  .navbar {
    width: 65%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .main_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    .hero_content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-basis: 45%;
      gap: 1rem;
      flex-direction: column;
      padding-left: 5%;
      padding-top: 4%;

      h2 {
        line-height: 3.5rem;
      }
      p {
        line-height: 1.8rem;
      }
    }

    .right_side {
      flex-basis: 45%;
      .image-container {
        position: relative;
        height: 750px;
      }

      img {
        width: 100%;
        height: 750px;
        object-fit: cover;
        clip-path: polygon(35% 0, 100% 0%, 100% 100%, 0% 100%);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 1s ease-in-out;
      }

      .image-visible {
        opacity: 1;
      }

      .image-hidden {
        opacity: 0;
      }
    }
  }

  @media (max-width: 750px) {
    .navbar {
      width: 90%;
    }
    .main_container {
      gap: 3rem;
      .hero_content {
        justify-content: center;
        align-items: center;
        margin-top: 25%;
        flex-basis: 100%;
        padding-left: 0%;
        h2,
        p {
          text-align: center;
        }
      }

      .right_side {
        flex-basis: 100%;
        img {
          height: 200px;
          clip-path: none;
          object-fit: contain;
        }
      }
    }
  }

  @media (min-width: 640px) and (max-width: 1330px) {
    .main_container {
      .hero_content {
        margin-top: 12%;
      }
    }
  }
`;
